import React from 'react';
import { Link } from "react-router-dom";

function NotFound(props) {

		return (
			<div className="main">
				<p>lost in the void! <Link to="/">please go back</Link> 😣</p>
			</div>
		)

}

export default NotFound;
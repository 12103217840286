import React from 'react';
import { Link } from "react-router-dom";

function Gallery(props){
	
	console.log(props.visual)

	return (
		<div className="main-gallery">
			<ul>
			{ props.visual.length > 0 && props.visual.map( item =>
				item.fields.Image && <Link to={ props.processUrl(item.fields.Name[0]) }>
					<img src={item.fields.Image[0].thumbnails.large.url}/>
					<p className="image-caption">{item.fields.Name} ⇉ image credit: {item.fields.Credit}</p>
				</Link>
			)}
			</ul>
		</div>
	)
}

export default Gallery;
import React, {useEffect, Link} from 'react';
import ReactMarkdown from 'react-markdown'
// import { TwitterEmbed, InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed';
import { BrowserView, MobileView } from 'react-device-detect';
import './About.css';

function About(props){
	console.log('page is', props.page)
	return (
		<>
		<BrowserView>
			<div className="about-holder">
			<div className="main-text">
				<ReactMarkdown>{props.page.fields.Text}</ReactMarkdown>
			</div>
			<div className="quotes">
				{props.quotes.length > 0 && 
					<ul className="quote-list">
						{props.quotes.map(quote => 
							<a href={quote.fields.Link}>
								<li>
									<p><i>{quote.fields.Text}</i></p>
									<p className='quote-source'>
										{quote.fields.Author && quote.fields.Author}
										{quote.fields.Source && quote.fields.Author && ", "}
										{quote.fields.Source && quote.fields.Source}
									</p>
								</li>
							</a>)} 
					</ul>
				}
			</div>
			</div>
		</BrowserView>
		<MobileView>
			<div className="main-text-mobile">
			<ReactMarkdown>{props.page.fields.Text}</ReactMarkdown>
			</div>
			<div className="main-text-mobile">
			<ReactMarkdown>{props.page.fields.Text}</ReactMarkdown>
			</div>
		</MobileView>
		</>
	)
}

export default About;
import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown'
// import { TwitterEmbed, InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed';
import { BrowserView, MobileView } from 'react-device-detect';
import './News.css'

function NewsItem(props) {
	const [clicked, setClicked] = useState(false);
	const [xOffset, setXOffset] = React.useState(0);
	const [yOffset, setYOffset] = React.useState(0);

	console.log('item text is', props.item.Text)
	const getRand = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1) ) + min;
	}

	return(
		<>
		{ props.item.Image ?
			<>
			{ clicked ? 
				<div className="news-item" style={{left: xOffset, top: yOffset}} 
					onClick={() => setClicked(false)}
					onMouseEnter={function() { setXOffset(getRand(-20,20)); setYOffset(getRand(-10,10)); }}
					// onMouseLeave={function() { setXOffset(0); setYOffset(0); }}
					>
					<p><b>{props.item.Date} -> {props.item.Title}</b><br/>
					<ReactMarkdown>{props.item.Text}</ReactMarkdown></p>
				</div>
				:
				<div className="news-item" style={{left: xOffset, top: yOffset}} 
					onClick={() => setClicked(true)}
					onMouseEnter={function() { setXOffset(getRand(-20,20)); setYOffset(getRand(-10,10)); }}
					// onMouseLeave={function() { setXOffset(0); setYOffset(0); }}
					>
					<img alt={"image text"} src={props.item.Image[0].thumbnails.large.url}/>
				</div>
			}
			</> :
				<div className="news-item" style={{left: xOffset, top: yOffset}} 
					onMouseEnter={function() { setXOffset(getRand(-20,20)); setYOffset(getRand(-10,10)); }}
					// onMouseLeave={function() { setXOffset(0); setYOffset(0); }}
					>
					<p><b>{props.item.Date} -> {props.item.Title}</b><br/>
					<ReactMarkdown>{props.item.Text}</ReactMarkdown></p>
			</div>
		}
		</>
		)
}

function NewsItemMobile(props) {
	const [clicked, setClicked] = useState(false);

	return(
		<>
		{ props.item.Image ?
			<>
			{ clicked ? 
				<div className="news-item-mobile"
					onClick={() => setClicked(false)}>
					<p><b>{props.item.Date} -> {props.item.Title}</b><br/>
					<ReactMarkdown>{props.item.Text}</ReactMarkdown></p>
				</div>
				:
				<div className="news-item-mobile"
					onClick={() => setClicked(true)}>
					<img alt={"image text"} src={props.item.Image[0].thumbnails.large.url}/>
				</div>
			}
			</> :
				<div className="news-item-mobile">
					<p><b>{props.item.Date} -> {props.item.Title}</b><br/>
					<ReactMarkdown>{props.item.Text}</ReactMarkdown></p>
			</div>
		}
		</>
		)
}

function News(props){
	return (
		<>
			<BrowserView>
				<div className="news-container">
				{/*randomise instead?*/}
					{ props.news.length > 0 && props.news.sort(function(a,b){
						  return new Date(b.fields.Date) - new Date(a.fields.Date);
						}).map( item => <NewsItem item={item.fields}/>
					)}
				</div>
			</BrowserView>
			<MobileView>
				<div className="news-container-mobile">
				{/*randomise instead?*/}
					{ props.news.length > 0 && props.news.sort(function(a,b){
						  return new Date(b.fields.Date) - new Date(a.fields.Date);
						}).map( item => <NewsItemMobile item={item.fields}/>
					)}
				</div>
			</MobileView>
		</>
	)
}

export default News;
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import dotenv from 'dotenv'; 
import './App.css';
import Home from './Home';
import About from './About';
import NotFound from './NotFound';
import Archive from './Archive';
import TooMuch from './components/TooMuch';
import Project from './Project';
const Airtable = require('airtable');

// configure env variables
dotenv.config()

const process_api_key = process.env.REACT_APP_AIRTABLE_API_KEY;
const airtable_base = process.env.REACT_APP_AIRTABLE_BASE;

function App() {

	const [projects, setProjects] = useState([]);
	const [menu, setMenu] = useState([]);
	const [news, setNews] = useState([]);
	const [quotes, setQuotes] = useState([]);
	const [press, setPress] = useState([]);
	const [visual, setVisual] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [gotProjects, setGotProjects] = useState(false);

	Airtable.configure({
			endpointUrl: 'https://api.airtable.com',
			apiKey: process_api_key
	});

	const base = Airtable.base(airtable_base);

	const processUrl = (string) => {
		let proc_url = ''

		try {
			proc_url = string.trim().replace(/[^\w\s']|_/g, "").replace(/\s+/g, "_").toLowerCase()
		}
		catch(err) {
			console.error(err)
		}

		return proc_url
	}

	//get menu first
	useEffect(()=>{
			base('Menu').select().all().then(records => {
					setMenu(records.filter(rec => rec.fields.Display === true).sort( (a, b) => a.fields.Order - b.fields.Order ));
			})
			.then(setIsLoading(false))
			.catch(err => {
					console.error(err);
			});

	},[])

	//then get projects
	useEffect(()=>{
			base('Projects').select().all().then(records => {
					const filtered = records.filter(record => record.fields.Display === true)
					setProjects(filtered.sort((a, b) => a.fields["Date From"] - b.fields["Date From"]));
					setGotProjects(true);
			})
			.catch(err => {
					console.error(err);
			});
	},[])

	useEffect(()=>{
			base('News2').select().all().then(records => {
					setNews(records)
			})
			.catch(err => {
					console.error(err);
			});
	},[])	

	useEffect(()=>{
			base('Press').select().all().then(records => {
					setPress(records)
			})
			.catch(err => {
					console.error(err);
			});
	},[])	

	useEffect(()=>{
			base('Visual').select().all().then(records => {
				setVisual(records.sort((a, b) => a.fields.Order - b.fields.Order))
			})
			.catch(err => {
					console.error(err);
			});
	},[])

	useEffect(()=>{
			base('Quotes').select().all().then(records => {
				setQuotes(records.sort((a, b) => a.fields.Order - b.fields.Order))
			})
			.catch(err => {
					console.error(err);
			});
	},[])	

	return (
		<div className="App">
			<Router>
				<Helmet>
					<meta name="description" content="hannah calascione's web site" />
					<meta name="keywords" content="hannah calascione" />
				</Helmet>
					{ isLoading ? 'loading...' : 
					<Routes>
						<Route exact path="/" element={<Home processUrl={processUrl} news={news} visual={visual} isLoading={isLoading} menu={menu} projects={projects} quotes={quotes}/>}/>
						<Route exact path="/all-work" element={<Archive  menu={menu} processUrl={processUrl} projects={projects}/>}/>
						<Route exact path="/not-found" element={<NotFound />}/>
						<Route exact path="/company" element={<TooMuch projects={projects} page={menu.find( item => item.fields.Field === 'Company')} processUrl={processUrl}/>}/>
						{ menu.length > 0 && menu.filter( item => item.fields.Ranking === 'second').map( item =>
							<Route path={"/all-work/:field"} element={<Archive menu={menu} entry={item}  processUrl={processUrl} projects={projects}/>}/> 
						)}
						{ gotProjects ? <Route path="/:id"element={<Project processUrl={processUrl} press={press} projects={projects}/>}/> : 'loading projects...' }
					</Routes>
				}
			</Router>
		</div>
	);
}

export default App;
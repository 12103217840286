import React, {useState, useEffect} from 'react';
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import { BrowserView, MobileView } from 'react-device-detect';

import './Project.css'
import './Home.css'
import './components/TooMuch.css'

function Project(props){
	const [project, setProject] = useState(null)
	const [press, setPress] = useState([])
	const [tooMuch, setTooMuch] = useState(false)

	let params = useParams();

	const { id } = params ? params : 1;

	useEffect(() => {
		if(project !== null) {
			if (project.fields.Field.includes('Company')) setTooMuch(true)
		}

	}, [project])

	useEffect(() => {
		if(props.projects.length > 0){
			const proj = props.projects.length > 0 ? props.projects.find(el => el.fields.Name && props.processUrl(el.fields.Name) === id) : null;
			if (proj) setProject(proj)
			else window.location.href  = '/not-found';
		}
	}, [props.projects])

	useEffect(() => {
		let temp_press = []

		if(project !== null && props.press.length > 0 && project.fields['Press']) {
			 project.fields['Press'].forEach( id => {
			 	temp_press.push(props.press.find(press => press.id === id))
			 })
			setPress(temp_press)
		}
	}, [props.press, project])

	return (
		<div className="project">
	 		{ props.isLoading ? 'loading' : 
	 		<div>
	 		<BrowserView>
	 			{ project && 
		 			<div className={tooMuch ? "too-much-container" : ''}>
		 				<div className="main">
		 				{tooMuch && <h1>hannah calascione -> company </h1>}
		 				<div className={tooMuch ? "too-much" : ''}>
			 				<h1>{ project.fields['Name'] }</h1>
			 				 <div className="nav-container">
			 					<div ><Link to="/">{"<- Home"}</Link></div>
								<div ><Link to="/all-work">{"<- Work"}</Link></div>
								{ tooMuch && <div ><Link to="/company">{"<- THIS PROJECT IS TOO MUCH"}</Link></div> } 
			 				</div>
			 				<div className="project">
			 				<div className="project-body">
				 				<div className="project-section">
				 					<h2>About:</h2>
				 					<ReactMarkdown>{ project.fields['Description'] }</ReactMarkdown>
				 				</div>
				 				{ project.fields['Credits'] && project.fields['Credits'].trim() !== '' && 
				 					<div className="project-section">
				 						<h2>Credits:</h2>
				 						<ReactMarkdown>{ project.fields['Credits'] }</ReactMarkdown>
				 						{ project.fields['Funder/Producer'] && <p>Funder/Producer: {project.fields['Funder/Producer']}</p>}
				 						{ project.fields['Venue'] && <p>Venue: {project.fields['Venue']}</p>}
				 					</div>
				 				}
				 				{ press.length > 0 && 
				 					<div className="project-section">
				 						<h2>Press:</h2>
				 						<ul className="intext-list">
				 							{ press.map((item, i) => <li><Link to={item.fields.link}>{item.fields.Name + " ↗"}</Link><br/>
				 								<ReactMarkdown>{item.fields.Extract}</ReactMarkdown></li>) }
				 						</ul>
				 					</div>
				 				}
			 				</div>
			 				{ project.fields['Images'] && project.fields['Images'].length > 0 &&
				 				<div className="project-gallery">
				 				{ project.fields['Images'].map( (image, i) => 
				 						<img alt={"image text"} key={i} src={image.thumbnails.large.url}/>
				 					)}
				 				{ project.fields['Image Credits'] && project.fields['Image Credits'].trim() !== '' && <p>Image credits: <span>{project.fields['Image Credits'] }</span></p>}
				 				</div>
			 				}
			 				</div>
			 			</div>
		 			</div>
	 			</div>
	 			}
	 		</BrowserView>
	 		{/* totally disgusting, improve */}
	 		<MobileView>
	 			{ project && 
	 			<div className={tooMuch ? "too-much-container" : ''}>
	 					 			<div className="main">
	 				{tooMuch && <h1>hannah calascione -> company </h1>}
	 				<div className={tooMuch ? "too-much-mobile" : ''}>
		 				<h1>{ project.fields['Name'] }</h1>
		 				 <div className="nav-container">
		 					<div ><Link to="/">{"<- Home"}</Link></div>
							<div ><Link to="/all-work">{"<- Work"}</Link></div>
							{ tooMuch && <div ><Link to="/company">{"<- THIS PROJECT IS TOO MUCH"}</Link></div> } 
		 				</div>
		 				<div className="project-section">
		 					<h2>About:</h2>
		 					<ReactMarkdown>{ project.fields['Description'] }</ReactMarkdown>
		 				</div>
		 				<div className="project-body">
		 				{ project.fields['Credits'] && project.fields['Credits'].trim() !== '' && 
		 					<div className="project-section">
		 						<h2>Credits:</h2>
		 						<ReactMarkdown>{ project.fields['Credits'] }</ReactMarkdown>
		 						{ project.fields['Funder/Producer'] && <p>Funder/Producer: {project.fields['Funder/Producer']}</p>}
		 						{ project.fields['Venue'] && <p>Venue: {project.fields['Venue']}</p>}
		 					</div>
		 				}
		 				{ project.fields['Images'] && project.fields['Images'].length > 0 &&
		 				<div className="project-section">
		 				{ project.fields['Images'].map( (image, i) => 
		 						<img alt={"image text"} key={i} src={image.thumbnails.large.url}/>
		 					)}
		 				{ project.fields['Image Credits'] && project.fields['Image Credits'].trim() !== '' && <p>Image credits: <span>{project.fields['Image Credits'] }</span></p>}
		 				</div>
		 				 }
		 				{ press.length > 0 && 
		 					<div className="project-section">
		 						<h2>Press:</h2>
		 						<ul className="intext-list">
		 							{ press.map((item, i) => <li><Link to={item.fields.link}>{item.fields.Name + " ↗"}</Link><br/>
		 								<ReactMarkdown>{item.fields.Extract}</ReactMarkdown></li>) }
		 						</ul>
		 					</div>
		 				}
		 				</div>
		 			</div>
	 			</div>
	 			</div>
	 			}
	 		</MobileView>
	 		</div>
			}
		</div>
	)
}

export default Project;